body {
  //color: #2b2b2b;
  background-color: $bfblack;
}

#root {
  padding-top: 0;
  @include media-breakpoint-up(lg) {
    padding: 3em 10vw;
    padding-bottom: 0;
  }
}

#main,
.product-content {
  background-color: $bfbeige;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

// NAV

.navbar-light {
  background: transparent !important;
  transition: background 0.2s;
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  body[data-scrolled] & {
    background-color: $bfbeige !important;
  }
}

.product-listing-page .navbar-brand {
  // opacity: 0;
  // transform: translateY(-10px);
  // transition: all 0.1s;
  // transition-delay: 0;
}

#header {
  background-color: $bfbeige;
  @include media-breakpoint-up(xl) {
    padding: 1.5em;
  }
  i.fa-user,
  i.fa-shopping-bag {
    color: $bfblack;
  }
}

body[data-scrolled] {
  #header {
    box-shadow: $box-shadow-lg;
  }
  .navbar-brand {
    opacity: 1;
    transition-delay: 0.2s;
    transform: translateY(0);
  }
}

#header .navbar .navbar-brand {
  img {
    height: 2.5rem;
    max-height: 2.5rem;

    @include media-breakpoint-up(md) {
      height: 3.5rem;
      max-height: 3.5rem;
    }

    &:hover,
    &:focus {
      filter: opacity(0.66);
    }
  }
}

.navbar .nav-item a {
  text-transform: uppercase;
  color: $bfpink;
}

.navbar-nav .hamburger {
  padding-left: 1.5em !important;
}

.navbar .hamburger {
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
}

.navbar-light .navbar-nav .nav-link {
  color: $bfblack;
  font-weight: bold;
}

.secondary-menu .menu {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

#main-menu {
  background: transparent !important;
  .nav-link {
    color: $bfpink;
  }
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.offcanvas-collapse {
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      color: #fff;
    }
    a.active,
    a:hover {
      color: $gray-500;
      font-style: italic;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: $bfpink, $alpha: 0.9) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  color: $bfred !important;
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $bfred !important;
  }
}

// MAKI

.maki {
  .maki-img-container {
    background-color: $bfpink;
  }
  transition: all 0.2s;
  transition-delay: 0;
  .card-title {
    //text-align: center;
    font-size: $h5-font-size;

    .sub-title {
      font-size: smaller;
    }
    .price {
      // margin-top: 0.5em;
      font-size: $font-size-base;
      font-weight: bold;
      color: $gray-500;
    }
    .former-price span {
      text-decoration: line-through;
    }
  }
  // eigentlich kleiner margin top über dem Preis. Manchmal ist aber der Former Price darüber, dann sollte der das margin bekommen.
  // daher bekommt das 4 Child Element im Card-Title den margin. -> Entweder former price oder price
  .card-title *:nth-child(4) {
    margin-top: 0.5em;
  }
  &:hover {
    .maki-img-container {
      background-color: $bfred;
      transform: rotate(-1deg);
      transition: all 0.2s;
      transition-delay: 0;
      box-shadow: $box-shadow-lg;
    }
  }
}

// PDP

.detail-page {
  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: $bfred;
}

// .blurry-background-image:before {
//   background-image: none;
// }

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $bfblack;
  color: $bfpink;
  a {
    color: $bfpink;
  }
  //border-bottom: 10px solid $primary;
}

#product-listing {
  padding-top: 2rem;
}
.maki .tag {
  top: 0;
  transform: rotate(-8deg) scale(1.5) translateY(-30px);
  will-change: transform;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// FIXES

ol.list-steps li.activatable a {
  color: $bfpink !important;
}

ol.list-steps li.activatable:before,
ol.list-steps li.active:before {
  border-color: $bfpink !important;
  background-color: darken($bfpink, 10%) !important;
}

ol.list-steps li.activatable:after,
ol.list-steps li.active:after {
  background-color: lighten($bfpink, 15%) !important;
}

ol.list-steps li:after {
  z-index: 0 !important;
}

ol.list-steps li:before {
  z-index: 1 !important;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// TICKETS

// Nigiri

.nigiri.card {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $gray-300;
  .nigiri-content {
    padding: 0 0 1.25rem;
    margin-top: 0.2rem;
    @include media-breakpoint-down(md) {
      display: table;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 0 0.6rem;
      line-height: 1.5rem !important;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }
  .nigiri-body {
    font-size: $h2-font-size;
    border-left: 0;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
      margin-bottom: 0.2rem;
      line-height: 1.5rem;
    }
  }
  .badge {
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 0.8em;
    @include media-breakpoint-down(sm) {
      top: 0.4rem;
    }
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    color: $gray-500;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-size: $h3-font-size;
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
    font-weight: 800;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.2rem;
    }
  }
  .nigiri-cta .btn {
    // color: $kaffyellow;
    // background: transparent;
    // border: 2px solid $kaffyellow;
    @include media-breakpoint-down(sm) {
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }
  }
}

// Ticket Detail Page

.ticket-detail-page {
  .card {
    border: none;
    border-radius: 0px;
  }
  .product-info-bar {
    background-color: $bfblack;

    color: $bfpink !important;
    .btn,
    i.far {
      color: $bfpink !important;
    }
  }
  .card.article-title {
    background-color: $bfred;
    .super-title {
      color: $bfpink;
    }
    h1 {
      color: $bfpink;
      font-size: $h2-font-size;
      // @include media-breakpoint-up(sm) {
      //   font-size: $h5-font-size;
      // }
      font-weight: 800;
      small {
        color: $bfpink;
      }
    }
  }
  article.ticket-detail {
    .DayPicker-Weekday {
      color: $black;
    }
    h2 {
      @extend .mb-0;
    }
    .nav-tabs {
      border-bottom: 2px solid $bfred;
    }
    .nav-tabs .nav-link {
      color: $bfred;
      border: 0;
      border-bottom: 1px solid $bfred;
      @extend .py-3;
    }
    .nav-tabs .nav-link.active {
      background: $bfred;
      color: $white;
      border-bottom: 1px solid $bfred;
      &:hover {
        //color: lighten($primary, 10%);
        background: rgba($bfred, 0.8);
        color: $white;
      }
    }
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
      border-bottom: 1px solid $bfred;
      background: $gray-100;
      color: $gray-500;
    }
  }
}
